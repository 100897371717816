import axiosInstance from "../../auth/axiosInstance";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";
import { Snackbar, Alert } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import dompurify from "dompurify";
import "../customCss/AddCours.css";

const AddCours = () => {
  let navigate = useNavigate();
  const { user } = AuthData();
  const id = user.id;
  const maxCharacters = 300;

  const emptytForm = {
    title: "",
    coursSubject: "",
    coursLevel: "",
    coursCountry: "MA",
    description: "",
    monthly: "",
    price: "",
    date_d: "",
    date_s: "",
    date_t: "",
    prof: { id },
  };

  const [cours, setCours] = useState(emptytForm);
  const {
    title,
    coursSubject,
    coursLevel,
    coursCountry,
    description,
    price,
    date_d,
    date_s,
    date_t,
  } = cours;

  const [monthly, setMonthly] = useState(cours.monthly || false);

  const handleToggle = (event) => {
    setMonthly(event.target.checked);
    handleChange({ target: { name: "monthly", value: event.target.checked } });
  };

  const StyledSwitch = styled(Switch)(({ theme, checked }) => ({
    "& .MuiSwitch-switchBase": {
      color: checked ? "green" : "#ff5733",
    },
    "& .MuiSwitch-track": {
      backgroundColor: checked ? "lightgreen" : "lightcoral",
    },
  }));

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const currencyCodes = {
    MA: "MAD",
    TN: "TND",
    EG: "EGP",
    AE: "AED",
    SA: "SAR",
    QA: "QAR",
    FR: "EUR",
    ES: "EUR",
    GB: "GBP",
    US: "USD",
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCours((prevCours) => ({ ...prevCours, [name]: value }));

    if (name === "date_d" || name === "date_s" || name === "date_t") {
      const dateObj = new Date(value);
      const options = { weekday: "long" };

      if (name === "date_d") {
        setDayOfWeek(dateObj.toLocaleDateString("fr-FR", options));
      } else {
        const index = name === "date_s" ? 0 : name === "date_t" ? 1 : -1;
        if (index !== -1) {
          const newDays = [...additionalDays];
          newDays[index] = dateObj.toLocaleDateString("fr-FR", options);
          setAdditionalDays(newDays);
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = await fetchXsrfToken();
      const sanitizedDescription = dompurify.sanitize(description);
      const sanitizedTitle = dompurify.sanitize(title);
      const coursToSend = {
        title: sanitizedTitle,
        coursSubject,
        coursLevel,
        coursCountry,
        description: sanitizedDescription,
        monthly,
        price,
        date_d,
        date_s,
        date_t,
        prof: { id },
      };

      await axiosInstance.post("/cours/cre", coursToSend, {
        withCredentials: true,
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
      });
      setCours(emptytForm);
      setSnackbarMessage("Le cours a été ajouté avec succès.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data ===
          "Merci de patienter jusqu'à ce que les autres cours soient validés"
      ) {
        setSnackbarMessage(
          "Veuillez patienter jusqu'à ce que les cours soient validés, ou contactez un administrateur."
        );
      } else {
        setSnackbarMessage(
          "Impossible de créer le cours. Veuillez réessayer plus tard."
        );
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    setTimeout(() => {
      navigate("/cours");
    }, 400);
  };

  const [showAdditionalDates, setShowAdditionalDates] = useState(true);
  const [additionalDatesCount, setAdditionalDatesCount] = useState(0);
  const [dayOfWeek, setDayOfWeek] = useState("");
  const [additionalDays, setAdditionalDays] = useState([]);

  const handleAddDateClick = () => {
    setAdditionalDatesCount((prevCount) => prevCount + 1);
    if (additionalDatesCount >= 2) {
      setShowAdditionalDates(false);
    }
  };

  const renderAdditionalDateFields = () => {
    return Array.from({ length: additionalDatesCount }, (_, index) => (
      <div key={index} className="mb-3">
        <input
          type="datetime-local"
          className="form-control"
          id={`additional_date_${index}`}
          name={`date_${index === 0 ? "s" : "t"}`}
          value={cours[`date_${index === 0 ? "s" : "t"}`] || ""}
          onChange={handleChange}
          required
        />
        {cours[`date_${index === 0 ? "s" : "t"}`] && (
          <div className="mt-2">
            {index === 0
              ? "Deuxième séance"
              : index === 1
              ? "Troisième séance"
              : `Séance ${index + 2}`}{" "}
            : {additionalDays[index]}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="container">
      <div className="py-4">
        <h2>Ajouter un Cours</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">
              Titre
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              value={cours.title}
              onChange={handleChange}
              required
            />
          </div>

          <div className="row mb-3">
            <div className="col">
              <label htmlFor="coursSubject" className="form-label">
                Matière
              </label>
              <select
                className="form-select"
                id="coursSubject"
                name="coursSubject"
                value={cours.coursSubject}
                onChange={handleChange}
                style={{ overflowY: "scroll" }}
                required
              >
                <option value="" disabled>
                  Veuillez choisir la matière
                </option>
                <optgroup label="Sciences">
                  <option value="maths">Mathématiques</option>
                  <option value="pc">Physique-Chimie</option>
                  <option value="svt">Sciences de la Vie et de la Terre</option>
                  <option value="science_ingenieur">
                    Sciences de l'Ingénieur
                  </option>
                </optgroup>
                <optgroup label="Langues">
                  <option value="arabe">Arabe</option>
                  <option value="anglais">Anglais</option>
                  <option value="francais">Français</option>
                  <option value="espagnol">Espagnol</option>
                </optgroup>
                <optgroup label="Matières littéraires">
                  <option value="education_islamique">
                    Éducation Islamique
                  </option>
                  <option value="philosophie">Philosophie</option>
                  <option value="histoire">Histoire</option>
                </optgroup>
                <optgroup label="Technologie">
                  <option value="info">Informatique</option>
                  <option value="electronique">Électronique</option>
                </optgroup>
                <optgroup label="Autres">
                  <option value="economie">Économie</option>
                  <option value="autre">Autre</option>
                </optgroup>
              </select>
            </div>
            <div className="col">
              <label htmlFor="coursLevel" className="form-label">
                Niveau
              </label>
              <select
                className="form-select"
                id="coursLevel"
                name="coursLevel"
                value={cours.coursLevel}
                onChange={handleChange}
                style={{ overflowY: "scroll" }}
                required
              >
                <option value="" disabled>
                  Veuillez choisir le niveau
                </option>
                <optgroup label="Université">
                  <option value="Universitaire">Universitaire</option>
                </optgroup>
                <optgroup label="Classes Préparatoires">
                  <option value="MP">Mathématiques et Physique (MP)</option>
                  <option value="PSI">
                    Physique et Sciences de l'Ingénieur (PSI)
                  </option>
                  <option value="TSI">
                    Technologie et Sciences Industrielles (TSI)
                  </option>
                  <option value="ECS">Économique et Commerciale (ECS)</option>
                  <option value="ECT">Économique et Commerciale (ECT)</option>
                </optgroup>
                <optgroup label="Lycée">
                  <option value="BAC_2">Terminale (BAC 2)</option>
                  <option value="BAC_1">Première (BAC 1)</option>
                  <option value="T_C">Tronc Commun (T.C)</option>
                </optgroup>
                <optgroup label="Collège">
                  <option value="College_3">3ème Collège</option>
                  <option value="College_2">2ème Collège</option>
                  <option value="College_1">1ère Collège</option>
                </optgroup>
                <optgroup label="Primaire">
                  <option value="Primaire_1">CP</option>
                  <option value="Primaire_2">CE1</option>
                  <option value="Primaire_3">CE2</option>
                  <option value="Primaire_4">CM1</option>
                  <option value="Primaire_5">CM2</option>
                  <option value="Primaire_6">6ème Primaire</option>
                </optgroup>
                <optgroup label="Autres">
                  <option value="Tout">Tous Niveaux</option>
                  <option value="Autre">Autre</option>
                </optgroup>
              </select>
            </div>

            <div className="col">
              <label htmlFor="coursCountry" className="form-label">
                Pays
              </label>
              <select
                className="form-select"
                id="coursCountry"
                name="coursCountry"
                value={cours.coursCountry}
                onChange={handleChange}
                style={{ overflowY: "scroll" }}
                required
              >
                <optgroup label="Pays">
                  <option value="MA">Maroc</option>
                  <option value="TN">Tunisie</option>
                  <option value="EG">Égypte</option>
                  <option value="AE">Émirats arabes unis</option>
                  <option value="SA">Arabie Saoudite</option>
                  <option value="QA">Qatar</option>
                  <option value="FR">France</option>
                  <option value="ES">Espagne</option>
                  <option value="GB">Royaume-Uni</option>
                  <option value="US">États-Unis</option>
                  <option value="XX">Autre</option>
                  <option value="UN">Monde entier</option>
                </optgroup>
              </select>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <div className="input-wrapper">
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={cours.description}
                onChange={handleChange}
                maxLength={maxCharacters}
              ></textarea>
              <span className="character-count">
                {cours.description.length}/{maxCharacters}
              </span>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="price" className="form-label">
              Prix
            </label>
            <div className="d-flex align-items-center justify-content-between">
              <div
                className="d-flex align-items-center"
                style={{ flexGrow: 1 }}
              >
                <input
                  type="text"
                  className="form-control me-2"
                  id="price"
                  name="price"
                  value={cours.price}
                  onChange={handleChange}
                  style={{ maxWidth: "95%" }}
                  required
                />
                <span>
                  {cours.coursCountry && currencyCodes[cours.coursCountry]}
                </span>
              </div>
              <FormControlLabel
                control={
                  <StyledSwitch
                    checked={monthly}
                    onChange={handleToggle}
                    name="monthlyToggle"
                  />
                }
                label={monthly ? "Par Mois" : "Par Séance"}
                labelPlacement="end"
                sx={{
                  color: monthly ? "green" : "red",
                  marginLeft: "10px",
                }}
              
              />
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="date_d" className="form-label">
              Emploi du temps
            </label>
            <input
              type="datetime-local"
              className="form-control"
              id="date_d"
              name="date_d"
              value={cours.date_d}
              onChange={handleChange}
              required
            />
            {cours.date_d && (
              <div className="mt-2">Première séance : {dayOfWeek}</div>
            )}
          </div>

          {showAdditionalDates && renderAdditionalDateFields()}

          {showAdditionalDates && additionalDatesCount < 2 && (
            <button
              type="button"
              className="btn btn-secondary"
              style={{ marginBottom: "10px", marginTop: "2px" }}
              onClick={handleAddDateClick}
            >
              Ajouter une séance
            </button>
          )}
          <br />
          <br />
          <button type="submit" className="btn btn-primary">
            Ajouter le cours
          </button>
          <Link to="/cours" className="btn btn-secondary ms-2">
            Annuler
          </Link>
        </form>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default AddCours;
