import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box, Container, Grid2, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

const Footer = () => {
  const navigate = useNavigate();

  const handleLinkClick = (path) => {
    navigate(path);
  };

  return (
    <Box
      component="footer"
      sx={{
        background: `linear-gradient(90deg, rgba(6,2,68,1) 8%, rgba(4,4,60,1) 23%, rgba(0,0,0,1) 64%)`,
        color: "#fff",
        p: 3,
        paddingTop: "20px",
        marginTop: "20px",
      }}
    >
      <Container maxWidth="lg">
        <Grid2 container spacing={4}>
          <Grid2 item xs={12} sm={4}>
            <Box display="flex" flexDirection="column">
              <Box mb={4}>
                <Link href="/" color="inherit">
                  <img
                    src="https://eswaye.com/Elogo.png"
                    alt="Home"
                    style={{ width: "100%", maxWidth: "120px" }}
                  />
                </Link>
              </Box>
              <Typography variant="h6" gutterBottom>
                A propos
              </Typography>
              <Typography variant="body2">
                E-SWAYE, Rejoignez notre plateforme des cours de soutiens
                électroniques innovante pour accéder à des cours de haute
                qualité, dispensés par des experts du domaine.
              </Typography>
            </Box>
          </Grid2>

          <Grid2 item xs={12} sm={4}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h5" gutterBottom>
                Contact
              </Typography>
              <br />
              <Typography variant="body1" gutterBottom>
                Support: support.esw@eswaye.com
              </Typography>
              <Typography variant="body1" gutterBottom>
                Administration: sup.eswaye@eswaye.com
              </Typography>
              <Typography variant="body1" gutterBottom>
                Téléphone: +212 656 214252
              </Typography>
            </Box>
          </Grid2>

          <Grid2 item xs={12} sm={4}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h5" gutterBottom>
                Politique
              </Typography>
              <br />
              <Link
                color="inherit"
                onClick={() => handleLinkClick("/policy")}
                sx={{
                  cursor: "pointer",
                  fontFamily: "cursive",
                  marginBottom: "5px",
                }}
              >
                Inscription
              </Link>

              <Link
                color="inherit"
                onClick={() => handleLinkClick("/policy")}
                sx={{
                  cursor: "pointer",
                  fontFamily: "cursive",
                  marginBottom: "5px",
                }}
              >
                Paiement
              </Link>

              <Link
                color="inherit"
                onClick={() => handleLinkClick("/policy")}
                sx={{
                  cursor: "pointer",
                  fontFamily: "cursive",
                  marginBottom: "5px",
                }}
              >
                Déroulement
              </Link>

              <Link
                color="inherit"
                onClick={() => handleLinkClick("/policy")}
                sx={{
                  cursor: "pointer",
                  fontFamily: "cursive",
                  marginBottom: "5px",
                }}
              >
                Utilisation
              </Link>
            </Box>
          </Grid2>

          <Grid2 item xs={12} sm={4}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h6" gutterBottom>
                Suivez-nous
              </Typography>
              <Box display="flex" alignItems="center">
                <Link href="https://www.facebook.com/" color="inherit">
                  <Facebook />
                </Link>
                <Link
                  href="https://www.instagram.com/"
                  color="inherit"
                  sx={{ pl: 1, pr: 1 }}
                >
                  <Instagram />
                </Link>
                <Link href="https://www.twitter.com/" color="inherit">
                  <Twitter />
                </Link>
              </Box>
            </Box>
          </Grid2>
        </Grid2>
        <hr />
        <Box mt={5}>
          <Typography variant="body2" align="center">
            {"Copyright © "}
            <Link color="inherit" href="/">
              ESWAYE
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;