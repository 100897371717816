import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosInstance from "../../auth/axiosInstance";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";
import DOMPurify from "dompurify";
import "../customCss/Account.css";
import WithdrawDialogU from "../../auth/WithdrawDialogU";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Snackbar, Alert } from "@mui/material";

const Account = () => {
  const { user } = AuthData();
  const [userData, setUserData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false);
  const [allFieldsEdited, setAllFieldsEdited] = useState(false);
  let Navigate = useNavigate();

  const [profileImage, setProfileImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageRef, setImageRef] = useState(null);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    axiosInstance
      .get(`/images/${user.id}`, { responseType: "blob" })
      .then((response) => {
        const imageUrl = URL.createObjectURL(response.data);
        setProfileImage(imageUrl);
      })
      .catch(() => setProfileImage(null));
  }, [user]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setSnackbarMessage(
          "La taille de l'image dépasse 2 Mo. Veuillez choisir une autre"
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
        setCrop({
          unit: "%",
          width: 50,
          height: 50,
          x: 25,
          y: 25,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  const handleSaveCrop = async () => {
    if (completedCrop && imageRef) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.naturalWidth / imageRef.width;
      const scaleY = imageRef.naturalHeight / imageRef.height;
      const ctx = canvas.getContext("2d");

      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;

      ctx.drawImage(
        imageRef,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width,
        completedCrop.height
      );

      canvas.toBlob(async (blob) => {
        const resizedFile = new File([blob], "image.jpg", {
          type: "image/jpeg",
        });

        const formData = new FormData();
        formData.append("file", resizedFile);

        try {
          const csrfToken = await fetchXsrfToken();
          await axiosInstance.post(`/images/${user.id}/upload`, formData, {
            withCredentials: true,
            headers: {
              "X-XSRF-TOKEN": csrfToken,
              "Content-Type": "multipart/form-data",
            },
          });

          const croppedImageUrl = URL.createObjectURL(blob);
          setProfileImage(croppedImageUrl);
          setUploadedImage(null);
          setCrop({
            unit: "%",
            width: 50,
            height: 50,
            x: 25,
            y: 25,
          });
          setSnackbarMessage("Image recadrée et téléchargée avec succès");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        } catch (error) {
          setSnackbarMessage(
            "Échec du téléchargement de l'image. Veuillez réessayer"
          );
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      }, "image/jpeg");
    }
  };

  const sanitizeInputs = (data) => {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = DOMPurify.sanitize(data[key]);
      return acc;
    }, {});
  };

  const handleWithdrawDialogOpen = () => {
    setOpenWithdrawDialog(true);
  };

  useEffect(() => {
    const editedFields = Object.values(userData).some((value) => value !== "");
    setAllFieldsEdited(editedFields);
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const csrfToken = await fetchXsrfToken();
        const response = await axiosInstance.get(`/account/inf`, {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error("Error");
      }
    };

    fetchData();
  }, [user.name]);

  const handleSave = async () => {
    try {
      const csrfToken = await fetchXsrfToken();
      const userDataJSON = {
        nom: userData.nom,
        prenom: userData.prenom,
        phone: userData.phone,
        compteBk: userData.compteBk,
        ecole: userData.ecole,
        ville: userData.ville,
      };
      const sanitizedData = sanitizeInputs(userDataJSON);

      await axiosInstance.put(`/account?email=${user.name}`, sanitizedData, {
        withCredentials: true,
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
      });

      setEditMode(false);
      Navigate("/");
    } catch (error) {
      console.error("Error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        setEditMode(false);
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <section className="account-section">
      <div className="account-container">
        <div className="profile-column">
          <div className="profile-card">
            <div
              className="profile-avatar"
              onClick={() => document.getElementById("imageUpload").click()}
              style={{
                cursor: "pointer",
                backgroundImage: profileImage ? `url(${profileImage})` : "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {!profileImage && (
                <>
                  <span>{userData.nom && userData.nom.charAt(0)}.</span>
                  <span>{userData.prenom && userData.prenom.charAt(0)}.</span>
                </>
              )}
            </div>
            <input
              type="file"
              id="imageUpload"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleImageUpload}
            />
            <p
              style={{
                textAlign: "center",
                marginTop: "10px",
                fontSize: "0.9rem",
                color: "#888",
              }}
            >
              Cliquer pour ajouter une photo
            </p>
            <div className="circle-text">{userData.userRole}</div>
            {uploadedImage && (
              <div>
                <ReactCrop
                  crop={crop}
                  onChange={(newCrop) => setCrop(newCrop)}
                  onComplete={handleCropComplete}
                  aspect={1}
                >
                  <img
                    src={uploadedImage}
                    alt="Crop source"
                    onLoad={(e) => setImageRef(e.currentTarget)}
                  />
                </ReactCrop>
                {completedCrop && (
                  <button
                    onClick={() => handleSaveCrop()}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#4CAF50",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      fontSize: "1rem",
                      cursor: "pointer",
                      transition: "background-color 0.3s ease",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#45a049")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#4CAF50")
                    }
                  >
                    Enregistrer l'image recadrée
                  </button>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="info-column">
          <div className="user-info-card">
            {[
              { label: "Nom", value: userData.nom, field: "nom" },
              { label: "Prénom", value: userData.prenom, field: "prenom" },
              { label: "E-mail", value: userData.email },
              { label: "Téléphone", value: userData.phone, field: "phone" },
              {
                label: "Banque RIB",
                value: userData.compteBk,
                field: "compteBk",
              },
              { label: "Ecole", value: userData.ecole, field: "ecole" },
              { label: "Ville", value: userData.ville, field: "ville" },
            ].map(
              (item, idx) =>
                item.value !== null && (
                  <div key={idx} className="user-info-row">
                    <div className="user-info-label">{item.label}</div>
                    <div className="user-info-value">
                      {editMode && item.field ? (
                        <input
                          type="text"
                          name={item.field}
                          value={item.value}
                          onChange={handleChange}
                          className="user-info-input"
                        />
                      ) : (
                        `${item.value}`
                      )}
                    </div>
                  </div>
                )
            )}
          </div>

          {!editMode && (
            <button className="edit-button" onClick={() => setEditMode(true)}>
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: "8px" }} />
              Edit
            </button>
          )}
          {editMode && (
            <button
              className="save-button"
              disabled={!allFieldsEdited}
              onClick={handleWithdrawDialogOpen}
            >
              <FontAwesomeIcon icon={faSave} style={{ marginRight: "8px" }} />
              Save
            </button>
          )}
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {openWithdrawDialog && (
        <WithdrawDialogU
          open={openWithdrawDialog}
          message="Veuillez saisir votre mot de passe pour continuer."
          handleSubmit={handleSave}
          handleClose={() => setOpenWithdrawDialog(false)}
          withdrawalData={{ idUser: user.id, idTarget: user.id }}
        />
      )}
    </section>
  );
};

export default Account;